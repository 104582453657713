import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { CoreDocument } from '@rallycry/api-suite-typescript/dist/models/CoreDocument'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { LookupsType } from './CoreDocRow'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { ScheduleTypography } from '@/components/molecules/text/ScheduleTypography'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  PartyFinderRoute,
  RootRoute
} from '@/core/route-keys'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useIsLgDown } from '@/core/hooks/useMediaQueries'

export const GenericCoreDocRow = ({ coreDoc }: { coreDoc: CoreDocument }) => {
  const { getPath } = useNavigation()
  const { t } = useRcTranslation()
  const isLgDown = useIsLgDown()
  const referenceType = coreDoc.reference?.type!
  const lookups = {
    [EntityType.BRACKET_MATCH]: {
      name: coreDoc.competition?.name,
      path: getPath({
        root: RootRoute.Competition,
        rootId: coreDoc.competition?.id,
        subRoute: CompetitionRoute.Match,
        subId: coreDoc.competitionMatch?.id
      })
    },
    [EntityType.ACTIVITY]: {
      name: coreDoc.event?.name,
      description: coreDoc.description,
      tagline: `${coreDoc.game?.name ? coreDoc.game?.name : null} ${t(
        'shared.party'
      )} - `,
      organizerName: (
        <RcTrans
          i18nKey='home:hosted-by'
          tOptions={{ host: coreDoc.organizer?.name }}
        />
      ),
      path: getPath({
        root: RootRoute.PartyFinder,
        subRoute: PartyFinderRoute.Party,
        subId: coreDoc.reference?.id
      })
    },
    [EntityType.COMPETITION_EVENT]: {
      name: coreDoc.competition?.name,
      description: coreDoc.competition?.disambiguatingDescription,
      path: getPath({
        root: RootRoute.Competition,
        rootId: coreDoc.competition?.id
      })
    },
    [EntityType.RALLY]: {
      name: coreDoc.game?.name,
      description: coreDoc.name,
      tagline: t('home:rally-tagline', {
        extra: coreDoc.game?.name ? coreDoc.game?.name : t('shared.tbd')
      }),
      path: getPath({
        root: RootRoute.PartyFinder,
        subRoute: PartyFinderRoute.Interest,
        subId: coreDoc.reference?.id
      })
    }
  } as LookupsType

  const eventName = (
    <Chip
      color='secondary'
      label={
        <>
          {coreDoc.competition ? (
            `${coreDoc.event?.name}` || (
              <RcTrans i18nKey='competition:event-kind.MATCH' />
            )
          ) : (
            <RcTrans i18nKey='party-finder:rally.title' />
          )}
          {referenceType === EntityType.ACTIVITY
            ? `${coreDoc.game?.name}`
            : null}
        </>
      }
    />
  )

  const schedule = (
    <Chip
      label={
        <ScheduleTypography
          startDate={coreDoc.event?.startDate}
          endDate={coreDoc.event?.endDate}
        />
      }
    />
  )

  const members = (
    <Chip
      icon={<FontAwesomeIcon icon={['fal', 'user']} opacity='0.4' />}
      label={
        <>
          {coreDoc.constituentsCount}
          {coreDoc.constituentsMax ? ` / ${coreDoc.constituentsMax}` : null}
        </>
      }
    />
  )

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={
        isLgDown
          ? { height: '100%', py: 1, px: 2 }
          : { height: '100%', py: 1, marginX: -1 }
      }
    >
      <Grid item xs>
        <NavigationLink
          googleTag='competition-conversion:core-doc'
          to={lookups[referenceType]?.path!}
          underline='none'
        >
          <AvatarText src={coreDoc.game?.icon} variant='rounded' size='xl'>
            <Typography color='text.primary' variant='h3' ml={1}>
              {lookups[referenceType]?.name}
            </Typography>
            <Grid container direction='row' spacing={1} mt={2}>
              <Grid item>
                <Typography
                  variant='subtitle2'
                  sx={theme => ({
                    color: theme.palette.getContrastText(
                      theme.palette.text.secondary
                    )
                  })}
                >
                  {eventName}
                </Typography>
              </Grid>
              <Grid item>{schedule}</Grid>
              {coreDoc.competition?.hideRegistration ? null : (
                <Grid item>{members}</Grid>
              )}
            </Grid>
          </AvatarText>
        </NavigationLink>
      </Grid>
    </Grid>
  )
}
